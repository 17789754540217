import {ElMessage} from 'element-plus';

export const page = {
  state: () => ({
    pages: [],
    pages_count: 0
  }),
  getters: {
    get_pages({pages}) {
      return pages
    },
    get_pages_count({pages_count}) {
      return pages_count
    }
  },
  mutations: {
    set_pages(state, payload) {
      return state.pages = payload
    },
    set_pages_count(state, payload) {
      state.pages_count = payload
    },
    new_page(state, payload) {
      state.pages = [payload, ...state.pages]
      state.pages_count += 1
    },
    save_page(state, payload) {
      let index = state.pages.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.pages[index] = payload
      }
    },
    delete_page(state, payload) {
      state.pages = state.pages.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.pages_count -= 1
    }
  },
  actions: {

    async api_get_pages({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'page',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_pages', res.data.pages)
        commit('set_pages_count', res.data.count)
      }
    },

    async api_add_page({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: 'page',
        data: payload
      })
    },

    async api_get_page({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `page/${payload}`
      })
    },

    async api_add_page_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: `page/translate`,
        data: payload
      })
      if (res.status === 201) {
        commit('save_page', res.data)
        ElMessage({
          message: `Перевод добавлен`,
          type: 'success',
        });
      }
    },

    async api_save_page({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: `page`,
        data: payload
      })
    },

    async api_page_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `page/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_page', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        })
      }
    },

    async api_delete_page({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `page/${payload}`)
      if (res.status === 200) {
        commit('delete_page', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        })
      }
    }
  }
}