import { ElMessage } from "element-plus";

export const galery = {
  state: () => ({
    galeries: [],
    galery: {},
    galeryCount: 0,
  }),
  getters: {
    galeryCount({ galeryCount }) {
      return galeryCount;
    },
    galeries({ galeries }) {
      return galeries;
    },
    galery({ galery }) {
      return galery;
    },
    activegalerys({ galeries }) {
      return galeries.filter((galery) => galery.status === true);
    },
  },
  mutations: {
    galeryCount(state, payload) {
      state.galeryCount = payload;
    },
    galeries(state, payload) {
      state.galeries = payload;
    },
    newgalery(state, payload) {
      state.galeries = [payload, ...state.galeries];
      state.galeryCount += 1;
    },
    savegalery(state, payload) {
      state.galeries = state.galeries.map((gal) => {
        if (gal._id === payload._id) return payload;
        return gal;
      });
    },
    deletegalery(state, payload) {
      state.galeries = state.galeries.filter((gal) => gal._id !== payload);
    },
  },
  actions: {
    async addGalery({ commit, dispatch }, payload) {
      let res = await dispatch("postAxios", {
        url: "galery",
        data: payload,
      });
      if (res.status == 201) {
        commit("newgalery", res.data);
      }
    },
    async saveGalery({ commit, dispatch }, payload) {
      let res = await dispatch("putAxios", {
        url: "galery",
        data: payload,
      });
      if (res.status == 201) {
        commit("savegalery", res.data);
      }
    },

    async getgalerys({ commit, dispatch }, payload) {
      commit("loading", true);
      let res = await dispatch("getAxios", {
        url: "galery",
        search: payload,
      });
      if (res.status === 200) {
        console.log(res.data.galery);
        commit("loading", false);
        commit("galeries", res.data.galery);
        commit("galeryCount", res.data.count);
      }
    },

    async getgalery({ dispatch }, payload) {
      return await dispatch("getAxios", {
        url: `galery/${payload}`,
      });
    },

    async changeStatusgalery({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: `galery/status/${payload._id}/${payload.status}`,
      });
      if (res.status === 201) {
        commit("savegalery", res.data);
        ElMessage({
          message: `Статус изменён`,
          type: "success",
        });
      }
    },

    async deletegalery({ dispatch, commit }, payload) {
      console.log(payload)
      let res = await dispatch("deleteAxios", `galery/${payload}`);
      console.log(res.status);
      if (res.status == 200) {
        commit("deletegalery", payload);
        ElMessage({
          message: res.data.message,
          type: "success",
        });
      }
    },
  },
};
