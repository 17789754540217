import {
  ElMessage
} from 'element-plus';

export const menu_pages = {
  state: () => ({
    menu_pages: [],
    menu_pages_count: 0,
    sortable_menu: []
  }),
  getters: {
    menu_pages_count(state) {
      return state.menu_pages_count;
    },
    menu_pages(state) {
      return state.menu_pages;
    },
    sortable_menu(state) {
      return state.sortable_menu;
    },
    actiive_menu_pages(state) {
      return state.menu_pages.filter((val) => val.parent === null);
    },
  },
  mutations: {
    set_menu_pages_count(state, payload) {
      state.menu_pages_count = payload;
    },
    set_menu_pages(state, payload) {
      state.menu_pages = payload;
    },
    set_sortable_menu(state, payload) {
      state.sortable_menu = payload;
    },
    new_menu_page(state, payload) {
      state.menu_pages = [payload, ...state.menu_pages];
      state.menu_pages_count += 1;
    },
    save_menu_page(state, payload) {
      let index = state.menu_pages.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.menu_pages[index] = payload;
      }
    },
    children_sort(state, payload) {
      let index = state.sortable_menu.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        let move_item = state.sortable_menu[index].parent.splice(payload.oldIndex, 1)[0]
        state.sortable_menu[index].parent.splice(payload.newIndex, 0, move_item)
      }
    },
    parent_sort(state, payload) {
      let move_item = state.sortable_menu.splice(payload.oldIndex, 1)[0]
      state.sortable_menu.splice(payload.newIndex, 0, move_item)
    },
    delete_menu_page(state, payload) {
      state.menu_pages = state.menu_pages.filter((val) => {
        if (val._id === payload) return false;
        return val;
      });
      state.menu_pages_count -= 1;
    },
  },
  actions: {

    async get_menu_pages({commit, dispatch}, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'menu-page',
        search: payload,
      });
      if (res.status === 200) {
        commit('loading', false);
        commit('set_menu_pages', res.data.menus);
        commit('set_menu_pages_count', res.data.count);
        commit('set_sortable_menu', res.data.sortable);
      }
    },

    async add_menu_page({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: 'menu-page',
        data: payload,
      })
    },

    async get_menu_page({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `menu-page/${payload}`,
      });
    },

    async add_menu_page_lang({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'menu-page/translate',
        data: payload
      })
      if (res.status === 201) {
        commit('save_menu_page', res.data);
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },

    async api_save_menu_page({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: `menu-page`,
        data: payload,
      })
    },

    async change_status_menu_page({dispatch, commit}, payload) {
      let res = await dispatch('getAxios', {
        url: `menu-page/status/${payload._id}/${payload.status}`,
      });
      if (res.status === 201) {
        commit('save_menu_page', res.data);
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    
    async delete_menu_page({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios',
        `menu-page/${payload}`,
      )
      if (res.status == 200) {
        commit('delete_menu_page', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },

    async api_post_menu_front({dispatch, commit}, payload) {
      let res = await dispatch('postAxios', {
        url: 'menu-page/frontmenu',
        data: payload
      })
      if (res.status === 201) {
        commit('set_sortable_menu', res.data);
      }
    },
  },
};