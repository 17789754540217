import cookie from 'vue-cookies'
import router from "@/router"
import {
  ElMessage
} from 'element-plus'

import {
  adminSettings
} from '@/router/admin'

export const auth = {
  state: () => ({
    user: {
      name: '',
      phone: ''
    },
    username: '',
    token: '',
    header: {},
    role: '',
    settingsLinks: [
      ...adminSettings,
    ]
  }),
  getters: {
    settingsLinks(state) {
      return state.settingsLinks
    },
    user(state) {
      return state.user
    },
    token(state) {
      return state.token
    },
    header(state) {
      return state.header
    },
    role(state) {
      return state.role
    },
    username(state) {
      return state.username
    }
  },
  mutations: {
    role(state, payload) {
      state.role = payload
    },
    user(state, payload) {
      state.user = payload
    },
    setToken(state, payload) {
      state.token = payload
    },
    header(state, payload) {
      state.header = payload
    },
    username(state, payload) {
      state.username = payload
    }
  },
  actions: {
    logIn(context, payload) {
      context.dispatch('postAxios', {
        url: 'auth/login',
        data: payload
      }).then(res => {
        if (res.status == 200) {
          if (res.data.message === 'Неверный пароль!')
            return ElMessage({
              message: 'Неверный пароль!',
              type: 'warning',
            })
          let {token, user} = res.data
          context.commit('user', user)
          context.commit('username', user.name)
          context.commit('setToken', token)
          context.dispatch('setLayout', `${user.role}-layout`)
          context.commit('role', user.role)
          context.commit('header', {
            "authorization": `Bearer ${token}`
          })
          cookie.set('quite-user', {
            user: user._id,
            token: token,
          })
          context.dispatch('api_all_menu')
          router.push('/')
        }
      }).catch(err => {
        let {
          response
        } = err
        cookie.remove('quite-user')
        ElMessage({
          message: response.data.message,
          type: 'warning',
        })
      })
    },
    checkUser(context, payload) {
      let {token} = payload
      context.commit('setToken', token)
      context.commit('header', {
        "authorization": `Bearer ${token}`
      })
      context.dispatch('getAxios', {
        url: 'auth/checkadmin'
      })
      .then(res => {
        if (res.status == 200) {
          context.commit('user', res.data)
          context.commit('username', res.data.name)
          context.commit('role', res.data.role)
          context.commit('layout', `${res.data.role}-layout`)
          if (router.currentRoute.value.name == 'login')
            router.push('/')
        } else {
          cookie.remove('quite-user')
          context.commit('layout', 'auth-layout')
        }
      }).catch(e => {
        console.log(e)
        cookie.remove('quite-user')
        context.commit('layout', 'auth-layout')
        ElMessage({
          message: 'Serverda xatolik',
          type: 'error',
        })
      })
    },
    exit(context) {
      cookie.remove('quite-user')
      context.commit('layout', 'auth-layout')
      context.commit('user', {})
      context.commit('setToken', '')
      context.commit('role', '')
      ElMessage({
        message: 'Tizimdan chiqdingiz',
        type: 'warning',
      })
      router.push('/login')
    },
    // checkLogin(context, payload) {
    //   return context.dispatch('postAxios', {
    //     url: `auth/checkphone`,
    //     data: payload
    //   }, {
    //     headers: context.getters.header
    //   })
    // },
    checkLogin({dispatch, getters}, payload) {
      return dispatch('postAxios', {
        url: `auth/loginverif`,
        data: payload
      }, {
        headers: getters.header
      })
    },
    // checkEmail(context, payload) {
    //   return context.dispatch('postAxios', {
    //     url: `auth/checkemail`,
    //     data: payload
    //   }, {
    //     headers: context.getters.header
    //   })
    // },
    // getProfile(context) {
    //   return context.dispatch('getAxios', {
    //     url: 'auth/my'
    //   })
    // },
    // resetPass({
    //   dispatch
    // }, payload) {
    //   return dispatch('postAxios', {
    //     url: 'bot',
    //     data: payload
    //   })
    // },
    // saveProfile(context, payload) {
    //   context.dispatch(`putAxios`, {
    //       url: 'auth/save',
    //       data: payload
    //     })
    //     .then(res => {
    //       if (res.status == 200) {
    //         context.commit('user', res.data)
    //         ElMessage({
    //           message: 'Ma`lumotlar yangilandi',
    //           type: 'success',
    //         })
    //         router.push('/');
    //       }
    //     })
    // }
  }
}