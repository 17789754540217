import axios from "axios"
import {
  ElMessage
} from 'element-plus'

export const helpers = {
  actions: {
    getAxios(context, payload) {
      return axios.get(`${context.getters.url}/${payload.url}`, {
        headers: context.getters.header,
        params: payload.search
      })
      .catch(e => {
        ElMessage({
          message: e.response.data.message,
          type: 'error',
        })
      })
    },
    postAxios(context, payload) {
      return axios.post(`${context.getters.url}/${payload.url}`, payload.data, {
        headers: context.getters.header
      }).catch(e => {
        ElMessage({
          message: e.response.data.message,
          type: 'error',
        })
      })
    },
    deleteAxios(context, payload) {
      return axios.delete(`${context.getters.url}/${payload}`, {
        headers: context.getters.header
      }).catch(e => {
        ElMessage({
          message: e.response.data.message,
          type: 'error',
        })
      })
    },
    putAxios(context, payload) {
      return axios.put(`${context.getters.url}/${payload.url}`, payload.data, {
        headers: context.getters.header
      }).catch(e => {
        ElMessage({
          message: e.response.data.message,
          type: 'error',
        })
      })
    },

    postOtherAxios(context, payload) {
      return axios.post(`${context.getters.uri}/${payload.url}`, payload.data, {
        headers: context.getters.header
      }).catch(e => {
        ElMessage({
          message: e.response.data.message,
          type: 'error',
        })
      })
    },
    getOtherAxios(context, payload) {
      return axios.get(`${context.getters.uri}/${payload}`, {
        headers: context.getters.header
      }).catch(e => {
        ElMessage({
          message: e.response.data.message,
          type: 'error',
        })
      })
    },
  }
}

export const addZero = (val) => {
  return val < 10 ? '0' + val : val
}