import {ElMessage} from 'element-plus';

export const subcategory = {
  state: () => ({
    subcategories: [],
    subcategory_count: 0,
  }),
  getters: {
    subcategories(state) {
      return state.subcategories
    },
    subcategory(state) {
      return state.subcategory
    },
    subcategory_count(state) {
      return state.subcategory_count
    },
    active_subcategories(state) {
      return state.subcategories.filter(sub => sub.status === true)
    }
  },
  mutations: {
    set_subcategories(state, payload) {
      state.subcategories = payload
    },
    set_sub_sount(state, payload) {
      state.subcategory_count = payload
    },
    new_subcategory(state, payload) {
      state.subcategories = [payload, ...state.subcategories]
      state.subcategory_count += 1
    },
    save_subcategory(state, payload) {
      let index = state.subcategories.findIndex(val => val._id == payload._id)
      if (index !== -1) {
        state.subcategories[index] = payload
      }
    },
    delete_subcategory(state, payload) {
      state.subcategories = state.subcategories.filter((sub) => {
        if (sub._id == payload) return false;
        return sub;
      });
      state.subcategory_count -= 1;
    },
  },
  actions: {

    async api_subcategories({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'subcategory',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_subcategories', res.data.subcategory)
        commit('set_sub_sount', res.data.count)
      }
    },

    async api_add_subcategory({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: 'subcategory',
        data: payload
      })
    },

    async api_get_sub({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `subcategory/${payload}`,
      })
    },

    async api_sub_add_lang ({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: 'subcategory/translate',
        data: payload
      })
      if (res.status === 201) {
        commit('save_subcategory', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },

    async api_save_sub({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: 'subcategory',
        data: payload
      })
    },

    async api_sub_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `subcategory/status/${payload._id}/${payload.status}`
      })
      if (res.status === 200) {
        commit('save_subcategory', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async api_delete_sub({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `subcategory/${payload}`)
      if (res.status === 200) {
        commit('delete_subcategory', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}