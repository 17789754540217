<template>
  <aside class="aside" v-if="!user.activeBot">
    <div class="aside__links">
      <router-link v-for="item,index of menu" :key="index" :to="item.path">
        <el-icon>
          <component :is="item.icon" />
        </el-icon>
        <div class="aside__name">{{ item.title }}</div>
      </router-link>
      <button @click="logout" class="aside__logout">
        <el-icon>
          <switch-button />
        </el-icon>
        Выйти
      </button>
    </div>
  </aside>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  data: () => ({}),
  props: ['menu'],
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    ...mapActions([
      'exit'
    ]),
    logout() {
      if (confirm('Tizimdan chiqmoqchimisiz?')) {
        this.exit()
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/part/aside.scss';
</style>