<template>
  <section :class="`main pd ${dark?'dark' : ''}`">
    <el-header/>
    <section class="wrapper">
      <el-aside :menu="filtered_menu"/>
      <el-main id="main" v-loading="loading">
        <router-view/>
      </el-main>
    </section>
  </section>
</template>

<script>
import ElHeader from '@/components/ElHeader.vue'
import ElAside from '@/components/ElAside.vue'
import { mapGetters } from 'vuex'
import { adminMenu } from '@/router/admin'
export default {
  data: () => ({
    menu: [
      {
        path: '/',
        icon: 'house',
        title: 'Главная страница'
      },
      
    ],
  }),
  components:{
    ElAside,
    ElHeader
  },
  computed: {
    ...mapGetters([
      'loading',
      'dark',
      'get_menu'
    ]),
    filtered_menu() {
      if (this.get_menu || this.get_menu?.length > 0) {
        return [...this.menu, ...adminMenu.filter(element => {
          return this.get_menu?.some(val => val.name === element.name)
        })]
      } else {
        return [...this.menu, ...adminMenu]
      }
    }
    
  },
  watch:{
    $route(){
      document.getElementById('main').scrollTo(0, 0)
    }
  },
}
</script>

<style>

</style>