<template>
  <h3 class="mb-20">Главная страница</h3>
  <el-row :gutter="16">
    <el-col :span="8">
      <div class="statistic-card">
        <el-statistic :value="stat_category">
          <template #title>
            <div style="display: inline-flex; align-items: center">
              Кол-во категориев
            </div>
          </template>
        </el-statistic>
      </div>
    </el-col>
    <el-col :span="8">
      <div class="statistic-card">
        <el-statistic :value="stat_news">
          <template #title>
            <div style="display: inline-flex; align-items: center">
              Кол-во новостей
            </div>
          </template>
        </el-statistic>
      </div>
    </el-col>
    <el-col :span="8">
      <div class="statistic-card">
        <el-statistic :value="stat_youtube" title="New transactions today">
          <template #title>
            <div style="display: inline-flex; align-items: center">
              Кол-во YouTube ссылок
            </div>
          </template>
        </el-statistic>
      </div>
    </el-col>
  </el-row>
  <!-- <by-region :data="statistic" /> -->
</template>

<script>
// import ByRegion from '@/components/statistic/ByRegion.vue';

import {mapActions, mapGetters} from 'vuex'
export default {
  components: {
    // ByRegion
  },
  computed: {
    ...mapGetters([
      'stat_category',
      'stat_news',
      'stat_youtube',
    ])
  },
  methods: {
    ...mapActions([
      'api_statistics'
    ])
  },
  mounted() {
    this.api_statistics()
  }
}
</script>

<style lang="scss">
.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}

</style>