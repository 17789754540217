import {ElMessage} from 'element-plus';

export const youtube_links = {
  state: () => ({
    youtube_links: [],
    youtube_links_count: 0,
  }),
  getters: {
    youtube_links(state) {
      return state.youtube_links
    },
    youtube_links_count(state) {
      return state.youtube_links_count
    },
  },
  mutations: {
    set_youtube_links(state, payload) {
      state.youtube_links = payload
    },
    set_youtube_links_sount(state, payload) {
      state.youtube_links_count = payload
    },
    new_youtube_links(state, payload) {
      state.youtube_links = [payload, ...state.youtube_links]
      state.youtube_links_count += 1
    },
    save_youtube_links(state, payload) {
      let index = state.youtube_links.findIndex(val => val._id == payload._id)
      if (index !== -1) {
        state.youtube_links[index] = payload
      }
    },
    delete_youtube_links(state, payload) {
      state.youtube_links = state.youtube_links.filter((val) => {
        if (val._id == payload) return false;
        return val;
      });
      state.youtube_links_count -= 1;
    },
  },
  actions: {

    async api_youtube_links({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'youtube',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_youtube_links', res.data.links)
        commit('set_youtube_links_sount', res.data.count)
      }
    },

    async api_add_youtube_links({dispatch, commit}, payload) {
      let res = await dispatch('postAxios', {
        url: 'youtube',
        data: payload
      })
      if (res.status === 201) {
        commit('new_youtube_links', res.data)
        ElMessage({
          message: `YouTube ссылка добавлена`,
          type: 'success',
        });
      }
    },

    async api_get_youtube_links({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `youtube/${payload}`,
      })
    },

    async api_save_youtube_links({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'youtube',
        data: payload
      })
      if (res.status === 201) {
        commit('save_youtube_links', res.data)
        ElMessage({
          message: `YouTube ссылка обновлена`,
          type: 'success',
        });
      }
    },

    async api_youtube_links_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `youtube/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_youtube_links', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async api_delete_youtube_links({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `youtube/${payload}`)
      if (res.status === 200) {
        commit('delete_youtube_links', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}