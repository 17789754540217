<template>
  <header class="header">
    <!-- <div class="header__search">
      <img src="@/assets/icons/search.svg" alt="">
      <input type="text" placeholder="Kalit so'z bo'yicha izlash">
    </div> -->
    <router-link to="/" class="header__logo">
      <img src="@/assets/logo.png" alt="" />

    </router-link>
    <div class="header__profile">
      <!-- <button @click="dark">
        <img src="@/assets/icons/sun.svg" alt="">
      </button> -->
      <router-link to="/settings/languages">
        <img src="@/assets/icons/settings.svg" alt="">
      </router-link>
      <button v-if="false">
        <img src="@/assets/icons/notification.svg" alt="">
      </button>
      <div class="header__menu">
        <div class="header__name">
          ADMIN
        </div>
        
        <div class="dropdown">
          <div class="dropbox">
            <!-- <router-link to="/profile">
              Shaxsiy sahifa
              <el-icon><operation/></el-icon>
            </router-link> -->
            <button @click="logout">
              Выйти
              <el-icon>
                <switch-button />
              </el-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  export default {
    data: () => ({
      search: '',

    }),
    computed: {
      ...mapGetters([
        'url',
        'user'
      ])
    },
    methods: {
      ...mapActions([
        'exit'
      ]),
      dark() {
        this.$store.commit('dark', !this.$store.getters.dark)
      },
      logout() {
        if (confirm('Tizimdan chiqmoqchimisiz?')) {
          this.exit()
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/part/header.scss';
</style>