import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/ru'
import Maska from 'maska'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'




const app = createApp(App)
.use(Maska)
.use(ElementPlus, { locale })
.use(VueCookies, { expire: '1d'})
.use(store)
.use(router)


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app')
