import {
  ElMessage
} from 'element-plus';

export const category = {
  state: () => ({
    categories: [],
    category: {},
    child: [],
    categoryCount: 0,
    childCount: 0,
  }),
  getters: {
    categoryCount(state) {
      return state.categoryCount;
    },
    childCount(state) {
      return state.childCount;
    },
    child_category(state) {
      return state.child
      // return state.child.filter(val => val.status === true)
    },
    categories(state) {
      return state.categories;
    },
    category(state) {
      return state.category;
    },
    activeCategorys(state) {
      return state.categories.filter((category) => category.status === true);
    },
  },
  mutations: {
    categoryCount(state, payload) {
      state.categoryCount = payload;
    },
    childCount(state, payload) {
      state.childCount = payload;
    },
    categories(state, payload) {
      state.categories = payload;
    },
    category(state, payload) {
      state.category = payload;
    },
    newCategory(state, payload) {
      if (payload.parent !== null) {
        state.child = [payload, ...state.child]
        state.childCount += 1;
      } else {
        state.categories = [payload, ...state.categories];
        state.categoryCount += 1;
      }
    },
    set_child(state, payload) {
      state.child = payload
    },
    saveCategory(state, payload) {
      if (payload.parent !== null) {
        let index = state.child.findIndex((dep) => dep._id == payload._id);
        if (index !== -1) {
          state.child[index] = payload;
        }
      } else {
        let index = state.categories.findIndex((dep) => dep._id == payload._id);
        if (index !== -1) {
          state.categories[index] = payload;
        }
      }
    },
    saveMainStatus(state, payload) {
      if (payload.parent === null) {
        state.categories = state.categories.map(val => {
          val.main = false
          if (val._id === payload._id) 
            val.main = true
          return val
        })
      }
    },
    deleteCategory(state, payload) {
      if (payload.parent !== null) {
        state.child = state.child.filter((val) => {
          if (val._id === payload._id) return false;
          return val;
        });
        state.childCount -= 1;
      } else {
        state.categories = state.categories.filter((category) => {
          if (category._id === payload._id) return false;
          return category;
        });
        state.categoryCount -= 1;
      }
    },
  },
  actions: {

    async getCategorys({commit, dispatch}, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'category',
        search: payload,
      });
      if (res.status === 200) {
        commit('loading', false);
        commit('categories', res.data.category);
        // commit('set_child', res.data.parent);
        commit('categoryCount', res.data.count);
      }
    },

    async getChildCategory({commit, dispatch}, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'category/child',
        search: payload,
      });
      if (res.status === 200) {
        commit('loading', false);
        commit('set_child', res.data.category);
        commit('childCount', res.data.count);
      }
    },

    async addCategory({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: 'category',
        data: payload,
      })
    },

    async getCategory({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `category/${payload}`,
      });
    },

    async addLanguage({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'category/translate',
        data: payload
      })
      if (res.status === 201) {
        commit('saveCategory', res.data);
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },

    async apiSaveCategory({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: `category`,
        data: payload,
      })
    },

    async changeStatusCategory({dispatch, commit}, payload) {
      let res = await dispatch('getAxios', {
        url: `category/status/${payload._id}/${payload.status}`,
      });
      if (res.status === 201) {
        commit('saveCategory', res.data);
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async changeMainCategory({dispatch, commit}, payload) {
      if (payload.status === false) {
        return ElMessage({
          message: `Вы не можете убрать "Главную" у этого категория!`,
          type: 'warning',
        });
      }
      let res = await dispatch('getAxios', {
        url: `category/main/${payload._id}/${payload.status}`,
      });
      if (res.status === 201) {
        commit('saveMainStatus', res.data);
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    
    async deleteCategory({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios',
        `category/${payload._id}`,
      )
      if (res.status == 200) {
        commit('deleteCategory', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },
  },
};