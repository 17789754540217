<template>
  <component :is="user.role"/>
</template>

<script>
import { mapGetters } from 'vuex';
// import Chart from 'chart.js/auto'
import HomeAdminPage from './HomePage.vue';
export default {
  name: 'HomeView',
  components: {
    'admin': HomeAdminPage,
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  
}
</script>

<style lang="scss">
@import '@/styles/view/home.scss';
</style>
