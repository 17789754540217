export const statistic = {
  state: () => ({
    category: 0,
    news: 0,
    youtube: 0
  }),
  getters: {
    stat_category({category}) {
      return category
    },
    stat_news({news}) {
      return news
    },
    stat_youtube({youtube}) {
      return youtube
    },

  },
  mutations: {
    set_stat_category(state, payload) {
      state.category = payload
    },
    set_stat_news(state, payload) {
      state.news = payload
    },
    set_stat_youtube(state, payload) {
      state.youtube = payload
    },
  },
  actions: {
    async api_statistics({commit, dispatch}) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'statistic/top',
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_stat_category', res.data.category)
        commit('set_stat_news', res.data.news)
        commit('set_stat_youtube', res.data.youtube)
      }
    },
  }
}