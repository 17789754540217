import {layoutApp} from './modules/layout'
import {auth} from './modules/auth'
import {helpers} from './modules/helpers'


import {category} from './data/category'
import {subcategory} from './data/subcategory'
import {news} from './data/news'
import {user} from './data/user'
import {page} from './data/page'
import {youtube_links} from './data/youtube.links'
import {statistic} from './data/statistic'
import { galery } from './data/galery'

//settings
import {languages} from './settings/languages'
import {keywords} from './settings/keyword'
import {menu_type} from './settings/menu_type'
import {links} from './settings/links'
import {menu_pages} from './settings/menu_page'


export const modulesList = {
  modules: {
    layoutApp,
    auth,
    helpers,

    galery,
    category,
    subcategory,
    news,
    user,
    page,
    youtube_links,
    statistic,
    

    // settings
    languages,
    keywords,
    menu_type,
    links,
    menu_pages
  }
}