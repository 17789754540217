import {ElMessage} from 'element-plus';

export const keywords = {
  state: () => ({
    keywords: [],
    keyword: {},
    keywords_count: 0
  }),
  getters: {
    keywords({keywords}) {
      return keywords
    },
    keyword({keyword}) {
      return keyword
    },
    keywords_count({keywords_count}) {
      return keywords_count
    }
  },
  mutations: {
    set_keywords(state, payload) {
      state.keywords = payload
    },
    set_keyword(state, payload) {
      state.keyword = payload
    },
    set_keywords_count(state, payload) {
      state.keywords_count = payload
    },
    new_keyword(state, payload) {
      state.keywords = [payload, ...state.keywords]
      state.keywords_count += 1
    },
    save_keyword(state, payload) {
      let index = state.keywords.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.keywords[index] = payload
      }
    },
    delete_keyword(state, payload) {
      state.keywords = state.keywords.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.keywords_count -= 1
    }
  },
  actions: {
    async api_keywords({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: `keyword`,
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_keywords', res.data.keyword)
        commit('set_keywords_count', res.data.count)
      }
    },

    async api_add_keyword({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: `keyword`,
        data: payload
      })
    },

    async api_get_keyword({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `keyword/${payload}`,
      })
    },

    async api_keyword_add_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: 'keyword/translate',
        data: payload
      })
      if (res.status == 201) {
        commit('save_keyword', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },

    async api_save_keyword({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: `keyword`,
        data: payload
      })
    },

    async api_delete_keyword({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `keyword/${payload}`)
      if (res.status === 200) {
        commit('delete_keyword', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}