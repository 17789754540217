<template>
  <component :is="layout" />
</template>


<script>
import AdminLayout from '@/layout/adminLayout.vue';
import workerLayout from '@/layout/workerLayout.vue';
import AuthLayout from '@/layout/authLayout.vue';
export default {
  components: {
    'admin-layout': AdminLayout,
    'worker-layout': workerLayout,
    'auth-layout': AuthLayout,
  },
  computed:{
    layout(){
      return this.$store.getters.layout
    },
  },
  methods:{
    checkUser(){
      if (this.$cookies.isKey('quite-user')){
        this.$store.dispatch('checkUser',this.$cookies.get('quite-user'))
      } else {
        this.$router.push('/login')
      }
    },
    // active_menu()
  },
  watch: {
    $route(to){
      if (to.name == 'login'){
        this.$store.dispatch('setLayout','auth-layout')
      } else {
        this.checkUser()
      }
      
    }
  },
  mounted(){
    this.checkUser()
    this.$store.dispatch('api_all_menu')
    document.title = 'DUNYO '
  }
}
</script>

<style lang="scss">
@import './styles/reset.scss';
@import './styles/grid.scss';
@import './styles/main.scss';
@import './styles/lib/class.scss';
</style>