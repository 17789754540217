export const adminSettings = [
  {
    path: 'languages',
    icon: 'List',
    name: 'languages',
    title: 'Языки',
    component: () => import(/* webpackChunkName: "languages" */ '../views/settings/LanguagesPage.vue')
  },
  {
    path: 'keywords',
    icon: 'Document',
    name: 'keywords',
    title: 'Ключеве слова',
    component: () => import(/* webpackChunkName: "languages" */ '../views/settings/KeywordsPage.vue')
  },
  // {
  //   path: 'link',
  //   icon: 'Link',
  //   name: 'links',
  //   title: 'Сслыки',
  //   component: () => import(/* webpackChunkName: "languages" */ '../views/settings/LinksPage.vue')
  // },
  // {
  //   path: 'sortable',
  //   icon: 'Grid',
  //   name: 'sortable',
  //   title: 'Сортировка меню',
  //   component: () => import(/* webpackChunkName: "languages" */ '../views/settings/SortablePage.vue')
  // },
  // {
  //   path: 'page-menu',
  //   icon: 'Menu',
  //   name: 'menu-page',
  //   title: 'Меню',
  //   component: () => import(/* webpackChunkName: "languages" */ '../views/settings/MenuPages.vue')
  // },
]

export const adminMenu = [
  {
    path: '/category',
    icon: 'files',
    name: 'categories',
    title: 'Категории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/CategoryPage.vue')
  },
  {
    path: '/childcategory',
    icon: 'Document',
    name: 'childcategories',
    title: 'Подкатегории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/ChildCategory.vue')
  },
  {
    path: '/subcategory',
    icon: 'Collection',
    name: 'subcategories',
    title: 'Субкатегории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/SubcategoryPage.vue')
  },
  {
    path: '/news',
    icon: 'List',
    name: 'news',
    title: 'Новости',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/NewsPage.vue')
  },
  {
    path: '/youtube',
    icon: 'Link',
    name: 'youtube',
    title: 'YouTube ссылки',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/YouTubeLinks.vue')
  },
  {
    path: '/galery',
    icon: 'picture',
    name: 'galery',
    title: 'Галерея',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/GaleryPage.vue')
  },
  // {
  //   path: '/pages',
  //   icon: 'DocumentAdd',
  //   name: 'page',
  //   title: 'Страницы',
  //   component: () =>
  //     import(/* webpackChunkName: "boss" */ '../views/PagesPage.vue')
  // },
];

export const limitedMenu = [
  {
    path: '/category',
    icon: 'files',
    name: 'categories',
    title: 'Категории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/CategoryPage.vue')
  },
  {
    path: '/subcategory',
    icon: 'Collection',
    name: 'subcategories',
    title: 'Субкатегории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/SubcategoryPage.vue')
  },
  {
    path: '/news',
    icon: 'List',
    name: 'news',
    title: 'Новости',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/NewsPage.vue')
  },
];

export const adminLinks = [
  ...adminMenu,
]