import {
  ElMessage
} from 'element-plus';

export const languages = {
  state: () => ({
    languages: [],
    language: {},
    languages_count: 0,
  }),
  getters: {
    // get_food_title ({title}) {
    //   return title
    // },
    get_languages_count ({languages_count}) {
      return languages_count
    },
    get_languages ({languages}) {
      return languages
    },
    get_language ({language}) {
      return language
    },
    get_active_language ({languages}) {
      return languages.filter(language => language.status === false)
    }
  },
  mutations: {
    set_language_count (state, payload) {
      state.languages_count = payload
    },
    set_languages (state, payload) {
      state.languages = payload
    },
    set_language (state, payload) {
      state.language = payload
    },
    new_language (state, payload) {
      state.languages = [payload, ...state.languages]
      state.languages_count += 1
    },
    save_language (state, payload) {
      let index = state.languages.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.languages[index] = payload
      }
    },
    delete_language (state, payload) {
      state.languages = state.languages.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.languages_count -= 1
    }
  },
  actions: {
    async api_get_languages ({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'language',
        search: payload,
      })
      if (res.status === 200) {
        commit('loading', false);
        commit('set_languages', res.data.languages);
        commit('set_language_count', res.data.count);
      }
    },

    async api_add_language ({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: 'language',
        data: payload,
      });
      if (res.status === 201) {
        ElMessage({
          message: `Язык добавлен`,
          type: 'success',
        });
        commit('new_language', res.data);
      }
    },

    async get_language({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `language/${payload}`
      })
    },

    async save_language({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'language',
        data: payload
      })
      if (res.status === 201) {
        commit('set_languages', res.data)
        ElMessage({
          message: `Язык изменён`,
          type: 'success',
        });
      }
    },

    async language_change_status({dispatch, commit}, payload) {
      let res = await dispatch('getAxios', {
        url: `language/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('set_languages', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async delete_language({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios', 
        `language/${payload}`
      )
      if (res.status === 200) {
        commit('delete_language', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }

  },
};